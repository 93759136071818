
.noData{
    height: 60px;
    line-height: 60px;
    text-align: center;
}
.cut-off{
    height: 30px;
    background: #F7F9FB;
    width: 100%;
}
.OperationPromotion{
    .storeSelection{
        border: 1px solid #EEEEEE;
        color: #333333;
        font-size: 14px;
        font-weight: 500;
        .selectionHeader{
            display: flex;
            background: #F5F5F5;
            height: 60px;
            line-height: 60px;
            .selectionItem{
                width: 16.6%;
                text-align: center;
            }
            .selectionItem:nth-child(1){
                text-align: left;
                padding-left: 26px;
                width: 8%;
            }
            .selectionItem:nth-child(2){
                text-align: left;
                width: 27%;
            }
        }
        .selectionBody{
            display: flex;
            height: 60px;
            line-height: 60px;
            border-bottom: 1px solid #EEEEEE;
            .selectionBodyItem{
                width: 16.6%;
                text-align: center;
            }
            .selectionBodyItem:nth-child(1){
                text-align: left;
                padding-left: 26px;
                width: 8%;
                i{
                    cursor: pointer;
                    color: #7D7D7D;
                    font-size: 14px;
                    margin-right: 5px;
                }
            }
            .selectionBodyItem:nth-child(2){
                text-align: left;
                width: 27%;
            }
        }
        .selectionFooter{
            display: flex;
            height: 80px;
            line-height: 80px;
            border-bottom: 1px solid #EEEEEE;
            .selectionFooterItem{
                width: 16.6%;
                text-align: center;
            }
            .selectionFooterItem:nth-child(1){
                text-align: left;
                padding-left: 26px;
                width: 8%;
            }
            .selectionFooterItem:nth-child(2){
                text-align: left;
                width: 27%;
            }
            .selectionFooterItem:nth-child(3){

            }
        }
    }
    .storeMarketing{
        border: 1px solid #EEEEEE;
        color: #333333;
        font-size: 14px;
        font-weight: 500;
        .marketingHeader{
            display: flex;
            background: #F5F5F5;
            height: 60px;
            line-height: 60px;
            .marketingItem:nth-child(1){
                text-align: left;
                padding-left: 26px;
                width: 8%;
            }
            .marketingItem:nth-child(2){
                text-align: left;
                width: 12%;
            }
            .marketingItem:nth-child(3){
                text-align: left;
                width: 29%;
            }
            .marketingItem:nth-child(4){
                text-align: left;
                width: 27%;
            }
            .marketingItem:nth-child(5){
                text-align: left;
                width: 22%;
            }
        }
        .marketingBody{
            display: flex;
            height: 60px;
            line-height: 60px;
            border-bottom: 1px solid #EEEEEE;
            .marketingBodyItem:nth-child(1){
                text-align: left;
                padding-left: 26px;
                width: 8%;
                i{
                    cursor: pointer;
                    color: #7D7D7D;
                    font-size: 14px;
                    margin-right: 5px;
                }
            }
            .marketingBodyItem:nth-child(2){
                text-align: left;
                width: 12%;
            }
            .marketingBodyItem:nth-child(3){
                text-align: left;
                width: 29%;
            }
            .marketingBodyItem:nth-child(4){
                text-align: left;
                width: 27%;
            }
            .marketingBodyItem:nth-child(5){
                text-align: left;
                width: 22%;
                span{
                    border: 1px solid #D1D2DB;
                    display: inline-block;
                    width: 82px;
                    height: 38px;
                    line-height: 38px;
                    border-radius: 4px;
                    padding-left: 14px;
                    margin-right: 6px;
                }
            }
        }
        .marketingFooter{
            .marketingFooter2{
                display: flex;
                height: 80px;
                line-height: 80px;
                border-bottom: 1px solid #EEEEEE;
            }
            .marketingFooterItem{
                /*padding: 0 20px;*/
            }
            .marketingFooterItem:nth-child(1){
                text-align: left;
                padding-left: 26px;
                width: 8%;
            }
            .marketingFooterItem:nth-child(2){
                text-align: left;
                width: 12%;
            }
            .marketingFooterItem:nth-child(3){
                text-align: left;
                width: 29%;
                span{
                    display: inline-block;
                    width: 90%;
                }
            }
            .marketingFooterItem:nth-child(4){
                text-align: left;
                width: 27%;
                span{
                    display: inline-block;
                    width: 80%;
                }
            }
            .marketingFooterItem:nth-child(5){
                text-align: left;
                width: 22%;
                span{
                    border: 1px solid #D1D2DB;
                    display: inline-block;
                    width: 82px;
                    height: 38px;
                    line-height: 38px;
                    border-radius: 4px;
                    padding-left: 14px;
                    margin-right: 6px;
                }
            }
        }
    }
    .examContent{
        border: 1px solid #EEEEEE;
        color: #333333;
        font-size: 14px;
        font-weight: 500;
        .contentHeader{
            display: flex;
            background: #F5F5F5;
            height: 60px;
            line-height: 60px;
            .contentItem:nth-child(1){
                text-align: left;
                padding-left: 26px;
                width: 8%;
            }
            .contentItem:nth-child(2){
                text-align: left;
                width: 32%;
            }
            .contentItem:nth-child(3){
                text-align: center;
                width: 12%;
            }
            .contentItem:nth-child(4){
                text-align: center;
                width: 12%;
            }
            .contentItem:nth-child(5){
                text-align: center;
                width: 12%;
            }
            .contentItem:nth-child(6){
                text-align: center;
                width: 12%;
            }
            .contentItem:nth-child(7){
                text-align: center;
                width: 12%;
            }
        }
        .contentBody{
            display: flex;
            height: 60px;
            line-height: 60px;
            border-bottom: 1px solid #EEEEEE;
            .contentBodyItem:nth-child(1){
                text-align: left;
                padding-left: 26px;
                width: 8%;
                i{
                    cursor: pointer;
                    color: #7D7D7D;
                    font-size: 14px;
                    margin-right: 5px;
                }
            }
            .contentBodyItem:nth-child(2){
                text-align: left;
                width: 32%;
            }
            .contentBodyItem:nth-child(3){
                text-align: center;
                width: 12%;
            }
            .contentBodyItem:nth-child(4){
                text-align: center;
                width: 12%;
            }
            .contentBodyItem:nth-child(5){
                text-align: center;
                width: 12%;
            }
            .contentBodyItem:nth-child(6){
                text-align: center;
                width: 12%;
            }
            .contentBodyItem:nth-child(7){
                text-align: center;
                width: 12%;
            }
        }
        .contentFooter{
            display: flex;
            height: 80px;
            line-height: 80px;
            border-bottom: 1px solid #EEEEEE;
            .contentFooterItem:nth-child(1){
                text-align: left;
                padding-left: 26px;
                width: 8%;
            }
            .contentFooterItem:nth-child(2){
                text-align: left;
                width: 32%;
            }
            .contentFooterItem:nth-child(3){
                text-align: center;
                width: 12%;
            }
            .contentFooterItem:nth-child(4){
                text-align: center;
                width: 12%;
            }
            .contentFooterItem:nth-child(5){
                text-align: center;
                width: 12%;
            }
            .contentFooterItem:nth-child(6){
                text-align: center;
                width: 12%;
            }
            .contentFooterItem:nth-child(7){
                text-align: center;
                width: 12%;
            }
        }
    }
}

